import React from 'react';
import "./App.css";
import CustomDate from "./CustomDate";
import CalendarApp from "./CalendarApp.js";
import Footer from "./Footer.js";

export default function EventsPage({ credentials, allEvents, setAllEvents, eventTypes, setEventTypes, setLoadEventsClicked }) {
  return (
    <div>
      <div className="title-container">
        <div className="title-banner">
          <h1>Events for {new CustomDate().getFullYear()}</h1>
        </div>
      </div>

      <div className="general-info-container">
        <h3 className="bold">Regular Programs</h3>

        <div className="programs-container">
          <div className="regular-program">
            <h4 className="bold">Counselling & Psychotherapy</h4>
            <ul>
              <li>Regular counselling for Individuals, Couples, Teenagers, Adults and Senior Citizens <em>on appointment basis</em></li>
              <li>Facility available for in-house/residential counselling or psychotherapy programmes for religious who require help</li>
            </ul>
          </div>

          <div className="regular-program">
            <h4 className="bold">Pyschological Assessment & Profiling</h4>
              <ul>
                <li>We use Standardised and Reliable, Objective and Projective assessment tools to assess a person. We are specialised in profiling people of the general population, employees for the corporate sector, and religious candidates.</li>
                <li>The assessment areas for the general population are Personality, Intelligence and Aptitude.</li>
                <li>For the corporate sector, we assess Personality, Intelligence, Aptitude and Emotional quotient.</li>
                <li>For the religious candiates, our assessment covers Personality, Intelligence, Aptitude, Emotional quotient and Spiritual quotient.</li>
              </ul>
          </div>

          <div className="regular-program">
            <h4 className="bold">Aptitude Testing and Career Guidance</h4>
            <ul>
              <li>Emmaus Wellness Centre conducts aptitude tests for the students, teenagers and adults. Based on the assessment, we counsel them regarding the career suitable for their future and guide them in choosing appropriate stream of studies</li>
            </ul>
          </div>
        </div>

        <div className="programs-container horizontal-container">
          <div className="program">
            <h3 className="bold">Main Program</h3>
            <div className="program-text">
              <h4 className="bold">Comprehensive Counselling/Therapy Program For 6 Months</h4>
              <p className="bold">(June 2024 to November 2024)</p>
              <p>The primary aim of this program is to help individuals to understand themselves in a deeper way and help themselves (self help). Secondly, we will enable and empower individuals to help others. This program is open for both residential participants as well as come & go participants. Interested applicants can either reside here at the Centre & attend the sessions or come and go.</p>
            </div>
          </div>

          <div className="program">
            <h3 className="bold">Retreats</h3>
            <div className="program-text">
              <p><span className="bold">Special Contemplative Retreats</span> are being held for religious, clergy and lay people in the months of September and November 2024. The Centre is open for guided as well as self directed retreats for religious, clergy & lay people.</p>
            </div>
          </div>

          <div className="program">
            <h3 className="bold">Booking Information</h3>
            <div className="program-text">
              <p><span className="bold">We are open for bookings!</span> The Centre has an air conditioned lecture hall as well as rooms for stay; hence it is open for seminars, workshops and conferences. Prior booking is required.</p>
            </div>
          </div>
          
        </div>

      </div>

      <CalendarApp credentials={credentials} allEvents={allEvents} setAllEvents={setAllEvents} eventTypes={eventTypes} setEventTypes={setEventTypes} setLoadEventsClicked={setLoadEventsClicked}/>
      <Footer />
    </div>
  );
};

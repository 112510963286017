import React, { useState, useMemo } from 'react';
import ExcelDisplay from "./ExcelDisplay.js";
import Calendar from "./Calendar.js";
import { EventHeader, EventFilter } from './EventComponents.js';
import { isBefore, isEqual, isAfter } from 'date-fns';

// make Button components
// 1st - POST request
// submit event -> POST request -> GET request
// every time page is loaded, make a GET request

export default function CalendarApp({ credentials, allEvents, setAllEvents, eventTypes, setEventTypes, setLoadEventsClicked }) {
    // const allEventTypes = ['None', 'Service', 'Appointment', 'Course', 'Workshop', 'Retreat', 'Program'];
    // const [eventTypes, setEventTypes] = useState([]);
    const [selectedDay, setSelectedDay] = useState(null);
    const [eventsForSelectedDay, setEventsForSelectedDay] = useState([]);

    function arraysAreEqual(array1, array2) {
        // checks length of arrays                if the same event is found in both arrays
        return array1.length === array2.length && array1.every((event, index) => event['id'] === array2[index]['id']);
    }

    function compareEvents(event1, event2) {
        if (isBefore(event1["start"], event2["start"])) {
            return -1;
        } else if (isEqual(event1["start"], event2["start"])) {
            return 0;
        } else if (isAfter(event1["start"], event2["start"])) {
            return 1;
        }
    }

    useMemo(() => {
        // code that relies on the updated state
        const sortedEvents = [...allEvents];
        sortedEvents.sort((a, b) => compareEvents(a, b) || a["Name"].localeCompare(b["Name"]));

        if (!arraysAreEqual(sortedEvents, allEvents)) {
            setAllEvents(sortedEvents); // only changes state if the array actually gets sorted -> prevents infinite rendering
        }

        setEventsForSelectedDay(allEvents);
        setSelectedDay(null);
        // console.log(allEvents);
    }, [allEvents, setAllEvents]); //  this code will log allEvents to the console whenever any of these allEvents changes

    return (
        <div className="calendar-app-container">
            <h3 className="bold">Our Calendar</h3>
            <Calendar events={allEvents} selectedDay={selectedDay} setSelectedDay={setSelectedDay} setEventsForSelectedDay={setEventsForSelectedDay}>
                <EventHeader allEvents={allEvents} selectedDay={selectedDay} setSelectedDay={setSelectedDay} setEventsForSelectedDay={setEventsForSelectedDay}>
                    <EventFilter events={eventsForSelectedDay} types={eventTypes} />
                </EventHeader>
            </Calendar>

            {
                credentials &&
                <section className="excel-input-container">
                    <ExcelDisplay setLoadEventsClicked={setLoadEventsClicked}/>
                </section>
            }
        </div>
    );
}
import React from 'react';
import "./App.css";
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <div className='footer-wrapper'>
            <footer className='footer'>
                <div>
                    <div className="logo">Emmaus Wellness</div>
                    <p>A unique and first of its kind centre in Goa.</p>
                    <div className="underline"><Link to="/about">About Us</Link> | <Link to="/events">Our Events</Link></div>
                    <br></br>
                </div>
                <div className='contact-text'>
                    <p><span className="bold">Contact:</span> 9423951027</p>
                    <p><span className="bold">Inquiry:</span> emmausmapusa@outlook.com</p>
                    <p><span className="bold">Address:</span> Emmaus Wellness Centre, Carmelite Fathers, Near The Court, Feira Alta, Mapusa, Goa 403507</p>
                </div>
            </footer>
        </div>
    );
}
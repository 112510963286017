import { React, useState, useMemo, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage.js';
import AboutPage from './AboutPage.js';
import EventsPage from './EventsPage.js';
import LoginPage from './LoginPage.js';
import Navigation from './Navigation.js';
import CustomDate from './CustomDate.js';
import { v4 as uuidv4 } from 'uuid';

export default function App() {
  const [credentials, setCredentials] = useState(null);
  // const credentials = null;
  const [allEvents, setAllEvents] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);
  const [loadEventsClicked, setLoadEventsClicked] = useState(false);

    const apiURL = "https://925ueirp9c.execute-api.us-east-1.amazonaws.com/Stage1"; 

    function fetchData() {
      try {
        fetch(apiURL).then(response => {
          if (!response.ok) {
              console.log("network response failed");
          }
          return response.json();
        }).then(data => {
          console.log("getting data"); // data is in json form
          data.forEach(eventObject => {
            // objects do not have start, end, or id property
            eventObject["start"] = new CustomDate(eventObject["Start Date"].replaceAll("/", "-"));
            eventObject["end"] = new CustomDate(eventObject["End Date"].replaceAll("/", "-"));
            eventObject["id"] = eventObject["Name"] + uuidv4();
          });
          console.log("setting data");
          setAllEvents(data);
          const typeData = data.map(eventObject => eventObject["Type"]);
          const aggregateTypes = typeData.reduce(function(object, item) {
            if (!object[item]) {
              object[item] = 0;
            }
      
            object[item]++;
            return object;
          }, {});
          let updatedTypes = [];
          for (let key in aggregateTypes) {
            // console.log(key);
            updatedTypes.push(key);
          }
          setEventTypes(updatedTypes);
        });
      } catch (e) {
        console.log(e);
      }
    }

    // fetching data upon initial refresh
    useEffect(() => { 
      fetchData(); 
    }, []);
    
    // fetching data upon loading uploaded events 
    useEffect(() => {
      if (loadEventsClicked) {
        fetchData();
        setLoadEventsClicked(false)
      }
    }, [loadEventsClicked]);

  const upcomingEvents = useMemo(() => {
      let events = [];
      let currentDate = new CustomDate();
      // find all events that come after the current date
      events = allEvents.filter((event1) => event1["end"].compareDates(currentDate) === 1);
      // find the four closest events to the current date
      return events.slice(0, 4);
  }, [allEvents]);

  return (
    <Router>
      <div>
        <Navigation />
        <Routes>
          <Route exact path="/" element={<HomePage upcomingEvents={upcomingEvents}/>} />
          <Route path="*" render={() => <h1>Page Not Found</h1>} />
          <Route path="/about" element={<AboutPage/>} />
          <Route path="/events" element={<EventsPage credentials={credentials} allEvents={allEvents} setAllEvents={setAllEvents} eventTypes={eventTypes} setEventTypes={setEventTypes} setLoadEventsClicked={setLoadEventsClicked}/>} />
          <Route path="/login" element={<LoginPage setCredentials={setCredentials}/>} />
        </Routes>
      </div>
    </Router>
  );
};
import React, { useState } from "react";
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';
import './App.css';

export function EventHeader({ children, selectedDay, allEvents, setSelectedDay, setEventsForSelectedDay }) {
  function handleCloseClick() {
    setSelectedDay(null);
    setEventsForSelectedDay(allEvents); 
  }

  return (
    <>
      <div className="event-header-container">
        {
          (selectedDay === null) ? (<p className="bold underline">Events</p>) : (<p className="bold underline">Events for {selectedDay}</p>)
        }
        {
          (selectedDay === null) ? (<button onClick={handleCloseClick} className="cursor-not-allowed opacity-50 mt-3 h-auto w-auto bg-transparent text-center font-semibold py-2 px-4 border border-blue rounded">Close</button>)
            : <button onClick={handleCloseClick} className="mt-3 h-auto w-auto bg-transparent text-center hover:bg-gray-200 font-semibold py-2 px-4 border border-blue  rounded">Close</button>
        }
      </div>
      <div className="event-filter-container">
        {children}
      </div>
    </>
  );
}

export function EventFilter({ events, types }) {
  const [selectedTypes, setSelectedTypes] = useState([]);

  const toggleType = (type) => {
    setSelectedTypes((previousState) => {
      if (previousState.includes(type)) {
        return previousState.filter((selected) => selected !== type);
      } else {
        return [...previousState, type];
      }
    })
  }

  const filteredEvents = events.filter((event) => (selectedTypes.length === 0 || selectedTypes.includes(event["Type"])));
  return (
    <>
      <div className="event-filter-bar">
        <span className="bold">Filter Events</span>
        <div>
          {
            types.map((type) => {
              return (
                <button key={type}
                  onClick={() => toggleType(type)}
                  className={clsx("event-filter-option hover:bg-actualBlue-200 focus:border-2 font-semibold border border-blue rounded",
                    {
                      "bg-actualBlue-200 rounded-full": selectedTypes.includes(type) === true,
                      "bg-white": selectedTypes.includes(type) === false
                    })}><span>{type}</span></button>
              );
            })
          }
        </div>
      </div>

      
      <EventTable events={filteredEvents} />
    </>
  );
}

export function EventTable({ events }) {
  return (
    <div className="event-table-container">
      <table className="event-table" tabIndex="0">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Date</th>
            <th className="description-td" scope="col">Description</th>
          </tr>
        </thead>
        <tbody>
          {
            (events.map(event => (
              <Event key={event["id"] + uuidv4()} eventObject={event} />
            ))
            )
          }
        </tbody>
      </table>
    </div>
  );
}

function Event({ eventObject }) {
  return (
    <tr className="">
      <td className="">{eventObject["Name"]}</td>
      <td className="date-td">
        {
          (eventObject["Start Date"] === eventObject["End Date"]) ?
            (eventObject["Start Date"]) : (eventObject["Start Date"] + " to " + eventObject["End Date"])
        }
      </td>
      <td className="description-td">{eventObject["Description"]}</td>
    </tr>
  );
}
import React from 'react';
import "./App.css";
import Footer from "./Footer.js";
import chapel from "./emmaus-pictures/chapel.jpg";
import dining from "./emmaus-pictures/dining-room.jpg";
import lecture from "./emmaus-pictures/lecture-room.jpg";
import room from "./emmaus-pictures/bedroom1.jpg";
import praveen from "./emmaus-pictures/PraveenDsouzaHeadshot.jpg";

export default function AboutPage() {
  return (
    <div>
      <div className="title-container">
        <div className="title-banner">
          <h1>About Us</h1>
        </div>
      </div>

      <div className='about-grid-wrapper'>
      <div className="grid-container">
        
          <div className='grid-div'>
            <div className='shadow'><img src={chapel} alt="The chapel at Emmaus, with a painting of Jesus breaking bread." /></div>
            <div className='centered-text'>
              <em><p>Emmaus Wellness Centre is a place to come and meet the risen Jesus and cast away all fears and anxieties.</p>
              <br />
              <p>There is a beautiful chapel with the theme of Emmaus' experience, Jesus breaking the bread with two disciples, depicted on the central wall.</p>
              </em>
            </div>
            <div className='shadow'><img src={dining} alt="The dining room at Emmaus, with a painting of Jesus and his disciples." /></div>
            <div className='shadow'><img src={room} alt="One of the 20 self-contained rooms for participants to stay at Emmaus during their programs." /></div>
            <div className='centered-text'>
              <em><p>There are 20 self contained rooms for the participants to stay and participate in the programmes.
                Each self-contained room has a desk, bed, AC, fan, wardrobe, and a full bathroom. There are two lecture rooms; each can accommodate 50 persons. There is a dining room which can accommodate 30 persons.
                There is ample space for parking as well.</p></em>
            </div>
            <div className='shadow'><img src={lecture} alt="One of the 2 lecture rooms at Emmaus, with individual desks and chairs, a podium, and a board." /></div>
          </div>
        </div>
      </div>

        <div className="founder-container">
          <div className='subheading'><h3>Director</h3></div>
          <div className='founder-grid shadow'>
            <div className='headshot'>
              <img src={praveen} alt="The director of Emmaus, Fr. Dr. Henry Praveen Dsouza." />
            </div>
            <div className="centered-text">
              <div>Fr. Dr. Henry Praveen D'Souza OCD is the director of Emmaus Wellness Centre. He holds a PhD in Counseling Psychology from De La Salle University, Manila, Philippines. </div>
              <div><span className="bold">Director Phone:</span> 8007593531 </div>
            </div>
          </div>
        </div>

      <Footer />
    </div>
  );
};


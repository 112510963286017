import { React, useState } from 'react';

export default function LoginPage( { setCredentials }) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [accepted, setAccepted] = useState(false);
    
    function handleUsernameChange(e) {
        setUsername(e.target.value);
    }

    function handlePasswordChange(e) {
        setPassword(e.target.value);
    }
    function handleSubmit(e) {
        e.preventDefault();
        console.log("Submitted!");
        checkCredentials(e);
    }

    async function checkCredentials(e) {
        const apiURL = "https://s1w4lip3t1.execute-api.us-east-1.amazonaws.com/Stage1";

        // the API should compare form data with the provided credentials
        const submittedCredentials = {
            Username : e.target[0].value,
            "Password" : e.target[1].value
        }
        fetch(apiURL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(submittedCredentials),
        }).then(response => {
            if (!response.ok) {
                console.log("network response failed");
            }
            return response.json();
        }).then(data => {
            if (data["body"] === "true") {
                console.log("Approved credentials");
                setCredentials("null");
                setAccepted(true);
            } else {
                console.log("Incorrect credentials");
                setCredentials(null);
                setAccepted(false);
            }
        })
    }

    return (
        <>
            <div className="title-container">
                <div className="title-banner">
                    <h1>Admin Login</h1>
                </div>
            </div>

            <form className="login-form" onSubmit={handleSubmit}>
                <label htmlFor="username"><h4 className='bold'>Username</h4></label>
                <input id="username" type="text" name="username" value={username} onChange={handleUsernameChange} placeholder='Enter username' required autoComplete="true" className="input-box" />
                <label htmlFor="password"><h4 className='bold'>Password</h4></label>
                <input id="password" type="password" name="password" value={password} onChange={handlePasswordChange} placeholder='Enter password' required autoComplete="true" className="input-box" />
                <button type="submit" className="submit-button">Login</button>
                { 
                    accepted && 
                    <p>Access Granted! <br></br> You can now go to the Events page to upload events.</p>
                }
            </form>
      </>
    )
};
import React, { useState } from "react";
import * as XLSX from "xlsx";
// import { v4 as uuidv4 } from 'uuid';
// import CustomDate from "./CustomDate";
import './App.css';

export default function ExcelDisplay({ setLoadEventsClicked }) {
  const [excelData, setExcelData] = useState([]);

  const handleFileUpload = (e) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data);
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      setExcelData(parsedData);
    }
  }

  async function loadExcelAsEvents() {
    setLoadEventsClicked(true);
    // handles pushing the json content to s3
    console.log("upload");
    const apiURL = "https://iwxe6edcyk.execute-api.us-east-1.amazonaws.com/Stage1/upload";

    const response = await fetch(apiURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(excelData),
    })

    if (!response.ok) {
      console.log("network response NOT OK");
    } else {
      console.log("it's POSTed!");
    }
  }

  return (
    <div className=" bg-white shadow-md rounded-xl px-8 pt-6 pb-8 my-5 w-full h-max border border-blue">
      <div className="instructions">
        <p className="bold">Before submitting an Excel file, make sure the following format is followed: </p>
        <ul>
          <li>There must be 5 columns: "Name", "Start Date", "End Date", "Type", and "Description".</li>
          <li>Some sample event types for the Type column are "Workshop", "Main Program", and "Summer Program".</li>
          <li>The dates entered for the Start and End Date columns must be in the format mm/dd/yyyy.</li>
          <li>Ensure that each column is formatted as "Text". This is located as a dropdown menu in the Number section of the Home tab.</li>
        </ul>
        <a href="https://s3.amazonaws.com/emmauswellness.com/Emmaus+2024+Brochure+Events.xlsx">Download a file of the 2024 events for an example of the required format. </a>
      </div>
      <label htmlFor="eventFile" className="block text-left text-m font-bold mb-1">Event File:</label>
      <input id="eventFile" onChange={handleFileUpload} type="file" accept=".xlsx, .xls" name="eventStart" required
        className="mx-auto shadow appearance-none border rounded w-full py-2 px-3 text-gray-500 leading-tight border-blue focus:border-black hover:cursor-pointer" />
      <button onClick={loadExcelAsEvents} className="mt-5 bg-transparent hover:bg-gray-200 font-semibold py-2 px-4 border border-blue  rounded text-center">Load Excel Events</button>

      <div className="excel-table-container">
        {
          excelData.length > 0 &&
          <table>
            <thead>
              <tr>
                {Object.keys(excelData[0]).map((key) => (
                  <th key={key}>{key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {excelData.map((row, index) => (
                <tr key={index}>
                  {Object.values(row).map((value, index) => (
                    <td key={index}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        }
      </div>
    </div>
  );
}